:root {
  --primary-color: #d32f2f; /* ESPN Red */
  --secondary-color: #b71c1c; /* Darker Red */
  --background-color: #f4f4f4;
  --text-color: #333;
  --header-font-size: 1.5rem;
  --cell-font-size: 1rem;
  --padding: 1rem;
  --border-radius: 0.5rem;
  --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --transition-duration: 0.3s;
}

body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.name-scout {
  max-width: 1200px;
  margin: 40px auto;
  padding: var(--padding);
  color: var(--text-color);
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.name-scout__title {
  font-size: calc(var(--header-font-size) * 2);
  font-weight: bold;
  margin-bottom: calc(var(--padding) * 2);
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.name-scout__description {
  font-size: calc(var(--cell-font-size) * 1.2);
  margin-bottom: calc(var(--padding) * 2);
  text-align: left; /* Changed from center to left */
  color: var(--text-color);
  line-height: 1.6;
}

.name-scout__table-container {
  overflow-x: auto;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-color: #fff;
  margin-bottom: calc(var(--padding) * 2);
}

.name-scout__table {
  width: 100%;
  border-collapse: collapse;
  font-size: var(--cell-font-size);
}

.name-scout__table-head {
  background-color: var(--primary-color);
  color: #fff;
}

.name-scout__table-header {
  padding: calc(var(--padding) * 1.5);
  text-align: left;
  font-weight: bold;
  border-bottom: 3px solid var(--secondary-color);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.name-scout__table-body {
  background-color: #fff;
}

.name-scout__table-row:nth-child(even) {
  background-color: #f9f9f9;
}

.name-scout__table-cell {
  padding: calc(var(--padding) * 1.5);
  border-bottom: 1px solid #eee;
  text-align: left;
}

.name-scout__table-row:hover .name-scout__table-cell {
  background-color: #f0f0f0;
}

.name-scout__loading {
  text-align: center;
  padding: calc(var(--padding) * 2);
  font-style: italic;
  color: #666;
  font-size: var(--cell-font-size);
}

@media (max-width: 768px) {
  .name-scout__table {
    font-size: calc(var(--cell-font-size) * 0.8);
  }

  .name-scout__table-header,
  .name-scout__table-cell {
    padding: calc(var(--padding) * 1.2);
  }
}