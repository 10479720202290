@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

:root {
  --lebanese-red: #EE161F;
  --lebanese-green: #00A64E;
  --white: #FFFFFF;
  --off-white: #F5F5F5;
  --light-gray: #E0E0E0;
  --dark-gray: #333333;
  --black: #000000;
  --transition-speed: 0.3s;
}

.team-stats-container {
  font-family: 'Oswald', sans-serif;
  line-height: 1.6;
  color: var(--dark-gray);
  background-color: var(--white);
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.team-stats-title {
  color: var(--lebanese-red);
  font-size: 3em;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.team-stats-filters {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.team-stats-select {
  font-family: 'Oswald', sans-serif;
  padding: 10px 20px;
  border: 2px solid var(--light-gray);
  border-radius: 5px;
  background-color: var(--white);
  color: var(--dark-gray);
  font-size: 1em;
  transition: all var(--transition-speed) ease;
}

.team-stats-select:focus {
  outline: none;
  border-color: var(--lebanese-green);
}

.team-stats-table-wrapper {
  overflow-x: auto;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.team-stats-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 1em;
}

.team-stats-table-header th {
  background-color: var(--lebanese-green);
  color: var(--white);
  padding: 15px 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.team-stats-table-header th,
.team-stats-table-body td {
  text-align: center;
}

.team-stats-table-header th[data-stat-type="team_name"],
.team-stats-cell-team_name {
  text-align: left;
}

.team-stats-table-header th::after {
  content: '';
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.team-stats-table-header th.sort-asc::after {
  content: '▲';
}

.team-stats-table-header th.sort-desc::after {
  content: '▼';
}

.team-stats-table-body td {
  padding: 15px 20px;
  border-bottom: 1px solid var(--light-gray);
}

.team-stats-row:last-child td {
  border-bottom: none;
}

.team-stats-cell-team_name {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 15px;
}

.team-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.team-stats-cell[data-stat-type="point_diff"][data-value^="+"] {
  color: var(--lebanese-green);
}

.team-stats-cell[data-stat-type="point_diff"][data-value^="-"] {
  color: var(--lebanese-red);
}

.team-stats-loading,
.team-stats-error {
  text-align: center;
  padding: 30px;
  font-size: 1.2em;
}

.team-stats-error {
  color: var(--lebanese-red);
}

@media (max-width: 768px) {
  .team-stats-title {
    font-size: 2.5em;
  }

  .team-stats-filters {
    flex-direction: column;
  }

  .team-stats-select {
    width: 100%;
  }

  .team-stats-table {
    font-size: 0.9em;
  }

  .team-stats-table-header th,
  .team-stats-table-body td {
    padding: 10px 15px;
  }
}