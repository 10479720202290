@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

:root {
  --soft-red: #e57373;
  --soft-green: #81c784;
  --off-white: #f5f5f5;
  --dark-gray: #333333;
  --light-gray: #e0e0e0;
}

body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  background-color: var(--off-white);
  color: var(--dark-gray);
  line-height: 1.6;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: var(--soft-red);
  padding: 20px;
  color: var(--off-white);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.App-header h1 {
  margin: 0;
  font-size: 2.8em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.App-header p {
  margin: 10px 0 0;
  font-size: 1.3em;
  font-weight: 400;
}

main {
  flex: 1;
}

footer {
  margin-top: auto;
  padding: 20px;
  background-color: var(--off-white);
  text-align: center;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

button, input, select, textarea {
  font-family: 'Oswald', sans-serif;
}