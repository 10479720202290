@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

:root {
  --lebanese-red: #EE161F;
  --lebanese-green: #00A64E;
  --white: #FFFFFF;
  --off-white: #F5F5F5;
  --light-gray: #E0E0E0;
  --dark-gray: #333333;
  --black: #000000;
  --transition-speed: 0.3s;
}

.todo {
  font-family: 'Oswald', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 20px;
  position: relative;
  overflow: hidden;
}

.todo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(to right, var(--lebanese-red), var(--lebanese-green));
}

.todo__title {
  color: var(--lebanese-red);
  font-size: 4em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 1;
}

.todo__title::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background-color: var(--lebanese-green);
  margin: 20px auto 0;
  border-radius: 2px;
}

.todo__description {
  font-size: 1.4em;
  margin-bottom: 50px;
  color: var(--dark-gray);
  text-align: center;
}

.todo__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.todo__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all var(--transition-speed) ease;
}

.todo__item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.todo__text {
  flex: 1;
  font-size: 1.2em;
  color: var(--dark-gray);
  padding-right: 20px;
}

.todo__button {
  padding: 10px 20px;
  background-color: var(--lebanese-green);
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all var(--transition-speed) ease;
}

.todo__button:hover {
  background-color: var(--lebanese-red);
  transform: translateY(-2px);
}

.todo__button:disabled {
  background-color: var(--light-gray);
  cursor: not-allowed;
}

.todo__upvotes {
  margin-left: 20px;
  font-weight: bold;
  color: var(--lebanese-red);
  font-size: 1.2em;
}

.todo__footer {
  padding-top: 40px;
  border-top: 1px solid var(--light-gray);
  text-align: center;
  color: var(--dark-gray);
  font-size: 1em;
}

.todo__link {
  color: var(--lebanese-green);
  text-decoration: none;
  font-weight: 600;
  transition: color var(--transition-speed) ease;
}

.todo__link:hover {
  color: var(--lebanese-red);
}

.todo__input {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  border: 2px solid var(--light-gray);
  border-radius: 5px;
  font-size: 1.1em;
  transition: border-color var(--transition-speed) ease;
}

.todo__input:focus {
  outline: none;
  border-color: var(--lebanese-green);
}

.todo__submit {
  display: block;
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: var(--lebanese-green);
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all var(--transition-speed) ease;
}

.todo__submit:hover {
  background-color: var(--lebanese-red);
  transform: translateY(-2px);
}

.todo__alert {
  padding: 15px;
  background-color: var(--lebanese-red);
  color: var(--white);
  border-radius: 5px;
  margin-top: 20px;
  font-size: 1.1em;
  text-align: center;
  box-shadow: 0 5px 15px rgba(238, 22, 31, 0.3);
}

.todo__alert--success {
  background-color: var(--lebanese-green);
  box-shadow: 0 5px 15px rgba(0, 166, 78, 0.3);
}

@media (max-width: 768px) {
  .todo {
    padding: 60px 20px;
  }

  .todo__title {
    font-size: 3em;
  }

  .todo__description {
    font-size: 1.2em;
  }

  .todo__item {
    flex-direction: column;
    align-items: flex-start;
  }

  .todo__text {
    margin-bottom: 15px;
  }

  .todo__button {
    width: 100%;
    margin-top: 10px;
  }

  .todo__upvotes {
    margin-left: 0;
    margin-top: 10px;
  }
}