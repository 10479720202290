@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

:root {
  --lebanese-red: #EE161F;
  --lebanese-green: #00A64E;
  --white: #FFFFFF;
  --off-white: #F5F5F5;
  --light-gray: #E0E0E0;
  --dark-gray: #333333;
  --black: #000000;
  --transition-speed: 0.3s;
}

.player-stats-container {
  font-family: 'Oswald', sans-serif;
  line-height: 1.6;
  color: var(--dark-gray);
  background-color: var(--white);
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.player-stats-title {
  color: var(--lebanese-red);
  font-size: 3em;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.player-stats-filters {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.player-stats-select {
  font-family: 'Oswald', sans-serif;
  padding: 10px 20px;
  border: 2px solid var(--light-gray);
  border-radius: 5px;
  background-color: var(--white);
  color: var(--dark-gray);
  font-size: 1em;
  transition: all var(--transition-speed) ease;
}

.player-stats-select:focus {
  outline: none;
  border-color: var(--lebanese-green);
}

.player-stats-table-wrapper {
  overflow-x: auto;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.player-stats-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 1em;
}

.player-stats-table thead th {
  background-color: var(--lebanese-green);
  color: var(--white);
  padding: 15px 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  height: 50px; /* Set a fixed height for all headers */
}

.player-stats-table thead th[data-stat-type="player_name"],
.player-stats-table thead th[data-stat-type="team_logo"],
.player-stats-table thead th[data-stat-type="team_name"] {
  text-align: left;
}

.player-stats-table tbody td {
  padding: 15px 20px;
  border-bottom: 1px solid var(--light-gray);
  text-align: center;
  vertical-align: middle;
}

.player-stats-table tbody td[data-stat-type="player_name"],
.player-stats-table tbody td[data-stat-type="team_logo"],
.player-stats-table tbody td[data-stat-type="team_name"] {
  text-align: left;
}

.player-stats-row:last-child td {
  border-bottom: none;
}

.player-stats-cell-player_name {
  font-weight: 600;
}

.team-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
  vertical-align: middle;
  margin-right: 10px;
}

.player-stats-cell[data-stat-type$="percentage"] {
  font-weight: 600;
  color: var(--lebanese-green);
}

.player-stats-loading,
.player-stats-error {
  text-align: center;
  padding: 30px;
  font-size: 1.2em;
}

.player-stats-error {
  color: var(--lebanese-red);
}

@media (max-width: 768px) {
  .player-stats-title {
    font-size: 2.5em;
  }

  .player-stats-filters {
    flex-direction: column;
  }

  .player-stats-select {
    width: 100%;
  }

  .player-stats-table {
    font-size: 0.9em;
  }

  .player-stats-table thead th,
  .player-stats-table tbody td {
    padding: 10px 15px;
  }

  .team-logo {
    width: 25px;
    height: 25px;
  }
}

/* Sort indicators */
.player-stats-table thead th::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px solid var(--white);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.player-stats-table thead th.sort-asc::after {
  border-top: none;
  border-bottom: 4px solid var(--white);
}

.player-stats-table thead th.sort-desc::after {
  border-bottom: none;
  border-top: 4px solid var(--white);
}