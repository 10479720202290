/* Base styles */
.about {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .about:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Typography */
  .about h1 {
    color: #ce1126;
    font-size: 3em;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
  }
  
  .about h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background-color: #00a86b;
  }
  
  .about h2 {
    color: #00a86b;
    font-size: 2em;
    margin-top: 40px;
    margin-bottom: 20px;
    position: relative;
    padding-left: 20px;
  }
  
  .about h2::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 30px;
    background-color: #ce1126;
  }
  
  .about p {
    color: #333;
    font-size: 1.2em;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  /* Sections */
  .about-section {
    margin-bottom: 50px;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .about-section:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Contact section */
  .contact-info {
    background-color: #f0f0f0;
    padding: 30px;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .contact-info h3 {
    color: #ce1126;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .contact-icon {
    font-size: 1.5em;
    margin-right: 15px;
    color: #00a86b;
  }
  
  /* Social media links */
  .social-links {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .social-link {
    display: inline-block;
    margin: 0 15px;
    font-size: 2em;
    color: #00a86b;
    transition: all 0.3s ease;
  }
  
  .social-link:hover {
    color: #ce1126;
    transform: scale(1.2);
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .about-section {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  .about-section:nth-child(1) { animation-delay: 0.1s; }
  .about-section:nth-child(2) { animation-delay: 0.3s; }
  .about-section:nth-child(3) { animation-delay: 0.5s; }
  .about-section:nth-child(4) { animation-delay: 0.7s; }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .about {
      padding: 30px 15px;
    }
  
    .about h1 {
      font-size: 2.5em;
    }
  
    .about h2 {
      font-size: 1.8em;
    }
  
    .about p {
      font-size: 1.1em;
    }
  }
  
  @media (max-width: 480px) {
    .about h1 {
      font-size: 2em;
    }
  
    .about h2 {
      font-size: 1.5em;
    }
  
    .about p {
      font-size: 1em;
    }
  
    .social-links {
      flex-wrap: wrap;
    }
  
    .social-link {
      margin: 10px;
    }
  }
  
  /* Accessibility */
  .about a:focus,
  .social-link:focus {
    outline: 2px solid #00a86b;
    outline-offset: 2px;
  }
  
  /* Print styles */
  @media print {
    .about {
      box-shadow: none;
      background-color: #fff;
    }
  
    .about h1::after,
    .about h2::before {
      display: none;
    }
  
    .social-links {
      display: none;
    }
  }
  
  /* Additional decorative elements */
  .about::before,
  .about::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #00a86b;
    opacity: 0.1;
    border-radius: 50%;
    z-index: -1;
  }
  
  .about::before {
    top: -25px;
    left: -25px;
  }
  
  .about::after {
    bottom: -25px;
    right: -25px;
  }
  
  /* Hover effects for sections */
  .about-section:hover h2 {
    color: #ce1126;
  }
  
  .about-section:hover p {
    color: #000;
  }
  
  /* Scroll reveal animation */
  .scroll-reveal {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s ease-out;
  }
  
  .scroll-reveal.revealed {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Custom scrollbar */
  .about::-webkit-scrollbar {
    width: 10px;
  }
  
  .about::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .about::-webkit-scrollbar-thumb {
    background: #00a86b;
    border-radius: 5px;
  }
  
  .about::-webkit-scrollbar-thumb:hover {
    background: #ce1126;
  }