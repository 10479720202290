@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

.contact-page {
  font-family: 'Oswald', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.contact-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(to right, var(--lebanese-red), var(--lebanese-green));
}

.contact-page h1 {
  color: var(--lebanese-red);
  font-size: 4em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  position: relative;
  z-index: 1;
}

.contact-page h1::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background-color: var(--lebanese-green);
  margin: 20px auto 0;
  border-radius: 2px;
}

.contact-page p {
  font-size: 1.4em;
  margin-bottom: 50px;
  color: var(--dark-gray);
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 60px;
}

.social-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--dark-gray);
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.8, 0.25, 1);
}

.social-link img {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
  transition: all var(--transition-speed) ease;
}

.social-link:hover {
  color: var(--lebanese-green);
  transform: translateY(-5px);
}

.social-link:hover img {
  filter: drop-shadow(0 5px 15px rgba(0, 166, 78, 0.4));
}

.email-option {
  margin-top: 60px;
  padding: 40px;
  background-color: var(--off-white);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.email-option p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.email-link {
  color: var(--lebanese-green);
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4em;
  transition: all var(--transition-speed) ease;
  position: relative;
  display: inline-block;
}

.email-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--lebanese-red);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.email-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.email-link:hover {
  color: var(--lebanese-red);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInUp {
  from { 
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.contact-page h1,
.contact-page p,
.social-links,
.email-option {
  animation: fadeIn 1s ease-out, slideInUp 0.8s ease-out;
}

.social-link:nth-child(1) { animation-delay: 0.1s; }
.social-link:nth-child(2) { animation-delay: 0.2s; }
.social-link:nth-child(3) { animation-delay: 0.3s; }

@media (max-width: 768px) {
  .contact-page {
    padding: 60px 20px;
  }

  .contact-page h1 {
    font-size: 3em;
  }

  .social-links {
    flex-direction: column;
    gap: 30px;
  }

  .social-link img {
    width: 50px;
    height: 50px;
  }

  .email-option {
    padding: 30px;
  }
}