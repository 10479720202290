@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

/* Header Base Styles */
.header {
    background-color: #1a1a1a;
    color: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    font-family: 'Oswald', sans-serif;
  }
  
  .header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(to right, #ED1C24, #00A651, #FFFFFF);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .header:hover::before {
    opacity: 1;
  }
  
  .header__container {
    max-width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    transition: height 0.3s ease;
  }
  
  /* Logo Styles */
  .header__logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
  }
  
  .header__logo:hover {
    transform: scale(1.05);
  }
  
  .header__logo img {
    height: 50px;
    margin-right: 1rem;
    transition: all 0.3s ease;
  }
  
  .header__logo span {
    position: relative;
    overflow: hidden;
  }
  
  .header__logo span::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  
  .header__logo:hover span::after {
    transform: translateX(0);
  }
  
  /* Navigation Styles */
  .header__nav {
    display: flex;
    gap: 1.5rem;
  }
  
  .header__nav-item {
    color: #FFFFFF;
    text-decoration: none;
    padding: 0.75rem 1.25rem;
    border-radius: 25px;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    font-family: 'Oswald', sans-serif;
  }
  
  .header__nav-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #00A651;
    z-index: -1;
    transition: all 0.3s ease;
  }
  
  .header__nav-item:hover::before,
  .header__nav-item--active::before {
    left: 0;
  }
  
  .header__nav-item:hover,
  .header__nav-item--active {
    color: #FFFFFF;
  }
  
  .header__nav-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #FFFFFF;
    transition: all 0.3s ease;
  }
  
  .header__nav-item:hover::after,
  .header__nav-item--active::after {
    width: 100%;
    left: 0;
  }
  
  /* Menu Toggle Styles */
  .header__menu-toggle {
    display: none;
  }
  
  /* Scroll Styles */
  .header--scrolled {
    background-color: rgba(26, 26, 26, 0.95);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .header--scrolled .header__container {
    height: 60px;
  }
  
  .header--scrolled .header__logo {
    font-size: 1.75rem;
  }
  
  .header--scrolled .header__logo img {
    height: 40px;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideInDown {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .header {
    animation: slideInDown 0.5s ease-out;
  }
  
  .header__nav-item {
    animation: fadeIn 0.5s ease-out;
    animation-fill-mode: both;
  }
  
  .header__nav-item:nth-child(1) { animation-delay: 0.1s; }
  .header__nav-item:nth-child(2) { animation-delay: 0.2s; }
  .header__nav-item:nth-child(3) { animation-delay: 0.3s; }
  .header__nav-item:nth-child(4) { animation-delay: 0.4s; }
  
  /* Hover Effects */
  .header__nav-item {
    position: relative;
    overflow: hidden;
  }
  
  .header__nav-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.5s;
  }
  
  .header__nav-item:hover::before {
    left: 100%;
  }
  
  /* Focus Styles for Accessibility */
  .header__nav-item:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .header__container {
      max-width: 960px;
    }
  
    .header__logo {
      font-size: 1.75rem;
    }
  
    .header__nav-item {
      font-size: 0.9rem;
      padding: 0.6rem 1rem;
    }
  }
  
  @media (max-width: 992px) {
    .header__container {
      max-width: 720px;
    }
  
    .header__logo {
      font-size: 1.5rem;
    }
  
    .header__logo img {
      height: 40px;
    }
  
    .header__nav-item {
      font-size: 0.8rem;
      padding: 0.5rem 0.75rem;
    }
  }
  
  @media (max-width: 768px) {
    .header__container {
      padding: 0 1rem;
    }
  
    .header__nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      background-color: #1a1a1a;
      padding: 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
    }
  
    .header__nav--open {
      display: flex;
    }
  
    .header__nav-item {
      text-align: center;
      padding: 1rem;
      font-size: 1rem;
    }
  
    .header__nav-item::before,
    .header__nav-item::after {
      display: none;
    }
  
    .header__menu-toggle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 21px;
      background: none;
      border: none;
      cursor: pointer;
      z-index: 10;
    }
  
    .header__menu-toggle span {
      height: 3px;
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 10px;
      transition: all 0.3s ease;
    }
  
    .header__menu-toggle--open span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
  
    .header__menu-toggle--open span:nth-child(2) {
      opacity: 0;
    }
  
    .header__menu-toggle--open span:nth-child(3) {
      transform: rotate(-45deg) translate(7px, -6px);
    }
  
    .header--scrolled .header__nav {
      top: 60px;
    }
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .header,
    .header__logo,
    .header__nav-item,
    .header__nav-item::before,
    .header__nav-item::after,
    .header__menu-toggle span {
      transition: none;
      animation: none;
    }
  }
  
  /* High Contrast Mode */
  @media (forced-colors: active) {
    .header {
      border-bottom: 2px solid CanvasText;
    }
  
    .header__nav-item:hover,
    .header__nav-item--active {
      outline: 2px solid CanvasText;
    }
  }
  
  /* Print Styles */
  @media print {
    .header {
      position: static;
      box-shadow: none;
    }
  
    .header__nav {
      display: none;
    }
  }
  
  /* Custom Scrollbar for Webkit Browsers */
  .header::-webkit-scrollbar {
    width: 8px;
  }
  
  .header::-webkit-scrollbar-track {
    background: #ED1C24;
  }
  
  .header::-webkit-scrollbar-thumb {
    background-color: #00A651;
    border-radius: 20px;
    border: 3px solid #ED1C24;
  }
  
  /* Additional Mobile Optimizations */
  @media (max-width: 480px) {
    .header__container {
      height: 60px;
    }
  
    .header__logo img {
      height: 35px;
    }
  
    .header__nav.header__nav--open {
      max-height: calc(100vh - 60px);
      overflow-y: auto;
    }
  
    .header__nav-item {
      font-size: 0.9rem;
      padding: 0.75rem;
    }
  }
  
  /* Dark Mode Support */
  @media (prefers-color-scheme: dark) {
    .header {
      background-color: #1a1a1a;
    }
  
    .header__nav-item:hover,
    .header__nav-item--active {
      background-color: #00A651;
    }
  
    .header--scrolled {
      background-color: rgba(26, 26, 26, 0.95);
    }
  }