@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&family=Roboto:wght@300;400;700&display=swap');

:root {
  --lebanese-red: #EE161F;
  --lebanese-green: #00A64E;
  --lebanese-red-light: #F5686F;
  --lebanese-green-light: #4FD68E;
  --white: #FFFFFF;
  --off-white: #F5F5F5;
  --light-gray: #E0E0E0;
  --medium-gray: #999999;
  --dark-gray: #333333;
  --black: #000000;
  --transition-speed: 0.3s;
  --box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  --text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--dark-gray);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.info-directory-wrapper {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Ctext x='50%25' y='50%25' font-size='70' text-anchor='middle' dominant-baseline='middle' font-family='Apple Color Emoji, Segoe UI Emoji, Noto Color Emoji, Android Emoji, EmojiSymbols, EmojiOne Mozilla, Twemoji Mozilla, Segoe UI Symbol'%3E🏀%3C/text%3E%3C/svg%3E");
  background-repeat: repeat;
  background-size: 100px 100px;
  min-height: 100vh;
}

.info-directory-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
}

.info-directory-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to right, var(--lebanese-red), var(--lebanese-green));
}

.info-directory-title {
  font-family: 'Oswald', sans-serif;
  font-size: 3.5rem;
  color: var(--lebanese-red);
  text-align: center;
  margin-bottom: 3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  text-shadow: var(--text-shadow);
}

.info-directory-title::after {
  content: ' 🏀';
}

.section-nav {
  background-color: var(--white);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  margin-bottom: 2rem;
}

.section-nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.section-nav li {
  margin: 0.5rem 1rem;
}

.section-nav a {
  color: var(--lebanese-green);
  text-decoration: none;
  font-weight: bold;
  transition: color var(--transition-speed) ease;
}

.section-nav a:hover {
  color: var(--lebanese-red);
}

.info-directory-content {
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  padding: 3rem;
  margin-bottom: 3rem;
  transition: all var(--transition-speed) ease;
  position: relative;
  overflow: hidden;
}

.info-directory-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.info-directory-content h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 2.2rem;
  color: var(--lebanese-green);
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.info-directory-content h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: var(--lebanese-red);
  transition: width 0.3s ease;
}

.info-directory-content:hover h2::after {
  width: 100px;
}

.info-directory-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.info-directory-link {
  display: inline-block;
  background-color: var(--lebanese-red);
  color: var(--white);
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all var(--transition-speed) ease;
}

.info-directory-link:hover {
  background-color: var(--lebanese-green);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.info-directory-glossary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.glossary-item {
  background-color: var(--white);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all var(--transition-speed) ease;
}

.glossary-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.glossary-item dt {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  color: var(--lebanese-red);
  margin-bottom: 1rem;
  font-size: 1.3rem;
  border-bottom: 2px solid var(--light-gray);
  padding-bottom: 0.5rem;
}

.glossary-item dd {
  font-size: 1rem;
  color: var(--dark-gray);
  margin-left: 0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .info-directory-container {
    padding: 2rem 1rem;
  }

  .info-directory-title {
    font-size: 2.5rem;
  }

  .info-directory-content {
    padding: 2rem;
  }

  .info-directory-content h2 {
    font-size: 1.8rem;
  }

  .info-directory-glossary {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .info-directory-title {
    font-size: 2rem;
  }

  .info-directory-content h2 {
    font-size: 1.6rem;
  }

  .info-directory-content p {
    font-size: 1rem;
  }

  .info-directory-link {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--lebanese-red);
  color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top::before {
  content: '\2191';
  font-size: 1.5em;
}

@media print {
  .info-directory-wrapper {
    background-image: none;
  }

  .info-directory-container {
    padding: 0;
    background-color: var(--white);
  }

  .info-directory-content {
    box-shadow: none;
    page-break-inside: avoid;
  }

  .info-directory-link, .scroll-to-top {
    display: none;
  }
}