@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

:root {
  --lebanese-red: #EE161F;
  --lebanese-green: #00A64E;
  --white: #FFFFFF;
  --off-white: #F5F5F5;
  --light-gray: #E0E0E0;
  --dark-gray: #333333;
  --black: #000000;
  --transition-speed: 0.3s;
}

body {
  font-family: 'Oswald', sans-serif;
  line-height: 1.6;
  color: var(--dark-gray);
  background-color: var(--white);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Hero Section */
.hero {
  background-image: url('../../public/basketball-court.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: var(--white);
  text-align: center;
  padding: 250px 20px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .hero {
    background-attachment: scroll; 
    background-position: center center; 
    padding: 150px 20px; 
  }
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(238, 22, 31, 0.9), rgba(0, 166, 78, 0.9));
  mix-blend-mode: multiply;
}

.hero h2 {
  font-size: 6em;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
  letter-spacing: 2px;
  font-weight: 700;
  transform: skew(-5deg);
}

.hero p {
  font-size: 2.2em;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  line-height: 1.5;
  font-weight: 400;
}

/* Features Section */
.features {
  padding: 180px 20px;
  background-color: var(--white);
  position: relative;
}

.features::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 12px;
  background: linear-gradient(to right, var(--lebanese-red), var(--lebanese-green));
}

.features h2 {
  text-align: center;
  color: var(--lebanese-red);
  margin-bottom: 120px;
  font-size: 4.5em;
  position: relative;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

.features h2::after {
  content: '';
  display: block;
  width: 180px;
  height: 8px;
  background-color: var(--lebanese-green);
  margin: 35px auto 0;
  border-radius: 4px;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 70px;
  max-width: 1500px;
  margin: 0 auto;
}

.feature-card {
  background-color: var(--white);
  border-radius: 30px;
  padding: 70px 50px;
  text-align: center;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  border: 4px solid var(--light-gray);
}

.feature-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(238, 22, 31, 0.2), transparent 70%);
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.feature-card:hover {
  transform: translateY(-30px) scale(1.05);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.3);
  border-color: var(--lebanese-green);
}

.feature-card:hover::before {
  top: -20%;
  left: -20%;
}

.feature-card h3 {
  color: var(--lebanese-green);
  margin-bottom: 35px;
  font-size: 2.6em;
  letter-spacing: 1px;
  font-weight: 600;
}

.feature-card p {
  color: var(--dark-gray);
  font-size: 1.4em;
  line-height: 1.7;
  font-weight: 400;
}

.feature-card.clickable {
  cursor: pointer;
}

.explore-button {
  display: inline-block;
  background-color: var(--lebanese-red);
  color: var(--white);
  padding: 15px 35px;
  border-radius: 50px;
  text-decoration: none;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-top: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.2em;
  position: relative;
  overflow: hidden;
  font-family: 'Oswald', sans-serif;
}

.explore-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, .3), transparent);
  transition: all .6s;
}

.explore-button:hover::before {
  left: 100%;
}

.explore-button:hover {
  background-color: var(--lebanese-green);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* CTA Section */
.cta {
  background-color: var(--lebanese-green);
  color: var(--white);
  text-align: center;
  padding: 140px 20px;
  position: relative;
  overflow: hidden;
}

.cta::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent 70%);
  transform: rotate(45deg);
}

.cta h2 {
  color: var(--white);
  margin-bottom: 40px;
  font-size: 4em;
  position: relative;
  letter-spacing: 2px;
}

.cta p {
  max-width: 800px;
  margin: 0 auto 50px;
  font-size: 1.6em;
  position: relative;
  line-height: 1.6;
  font-weight: 400;
}

.cta-button {
  background-color: var(--white);
  color: var(--lebanese-green);
  border: none;
  padding: 18px 45px;
  font-size: 1.4em;
  border-radius: 50px;
  cursor: pointer;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.8, 0.25, 1);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  font-family: 'Oswald', sans-serif;
  display: inline-block;
  text-decoration: none;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, .5), transparent);
  transition: all .6s;
}

.cta-button:hover::before {
  left: 100%;
}

.cta-button:hover {
  background-color: var(--lebanese-red);
  color: var(--white);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h2 {
    font-size: 3em;
  }

  .hero p {
    font-size: 1.3em;
  }

  .feature-grid {
    grid-template-columns: 1fr;
  }

  .features h2 {
    font-size: 2.7em;
  }

  .cta h2 {
    font-size: 2.7em;
  }

  .cta p {
    font-size: 1.2em;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInUp {
  from { 
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.hero h2,
.hero p {
  animation: fadeIn 1.5s ease-out, slideInUp 1s ease-out;
}

.feature-card {
  animation: fadeIn 1s ease-out, slideInUp 0.8s ease-out;
}

.cta h2,
.cta p,
.cta-button {
  animation: fadeIn 1s ease-out, slideInUp 0.8s ease-out;
}

/* Staggered animation for feature cards */
.feature-card:nth-child(1) { animation-delay: 0.1s; }
.feature-card:nth-child(2) { animation-delay: 0.2s; }
.feature-card:nth-child(3) { animation-delay: 0.3s; }
.feature-card:nth-child(4) { animation-delay: 0.4s; }
.feature-card:nth-child(5) { animation-delay: 0.5s; }
.feature-card:nth-child(6) { animation-delay: 0.6s; }
.feature-card:nth-child(7) { animation-delay: 0.7s; }

/* Additional Enhancements */
.feature-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--lebanese-red), var(--lebanese-green));
  transform: scaleX(0);
  transition: transform var(--transition-speed) ease;
}

.feature-card:hover::after {
  transform: scaleX(1);
}

.cta::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15px;
  background: linear-gradient(to right, var(--lebanese-red), var(--white), var(--lebanese-green));
}

/* Scroll Indicator */
.scroll-indicator {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.scroll-indicator::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  border: 2px solid var(--white);
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  animation: scrollIndicator 2s infinite;
}

@keyframes scrollIndicator {
  0% { transform: rotate(45deg) translate(0, 0); opacity: 0; }
  50% { opacity: 1; }
  100% { transform: rotate(45deg) translate(20px, 20px); opacity: 0; }
}

/* Hover Effect for Feature Cards */
.feature-card:hover h3 {
  color: var(--lebanese-red);
  transition: color var(--transition-speed) ease;
}

/* Fancy Underline Effect for Links */
.homepage a:not(.explore-button):not(.cta-button) {
  position: relative;
  text-decoration: none;
  color: var(--lebanese-red);
}

.homepage a:not(.explore-button):not(.cta-button)::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--lebanese-green);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.homepage a:not(.explore-button):not(.cta-button):hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.latest-news {
  margin: 4rem auto;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  position: relative;
  overflow: hidden;
}

.latest-news::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to right, var(--lebanese-red), var(--lebanese-green));
}

.latest-news h2 {
  color: var(--lebanese-red);
  font-size: 3em;
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 700;
}

.latest-news ul {
  list-style-type: none;
  padding: 0;
}

.latest-news li {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  transition: all var(--transition-speed) ease;
}

.latest-news li:hover {
  background-color: var(--off-white);
  transform: translateX(10px);
}

.latest-news a {
  color: var(--dark-gray);
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 500;
  display: block;
  margin-bottom: 0.3rem;
}

.latest-news a:hover {
  color: var(--lebanese-green);
}

.news-date {
  font-size: 1rem;
  color: var(--lebanese-red);
  font-weight: 400;
  display: block;
}

@media (max-width: 768px) {
  .latest-news {
    padding: 1.5rem;
    margin: 2rem auto;
  }

  .latest-news h2 {
    font-size: 2em;
  }

  .latest-news li {
    padding: 0.8rem;
  }

  .latest-news a {
    font-size: 1em;
  }
}

/* CTA Button Styles */
.cta-button {
  background-color: var(--white);
  color: var(--lebanese-green);
  border: none;
  padding: 18px 45px;
  font-size: 1.3em;
  border-radius: 50px;
  cursor: pointer;
  transition: all var(--transition-speed) cubic-bezier(0.25, 0.8, 0.25, 1);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.4em;
  display: inline-block;
  text-decoration: none;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, .5), transparent);
  transition: all .6s;
}

.cta-button:hover::before {
  left: 100%;
}

.cta-button:hover {
  background-color: var(--lebanese-red);
  color: var(--white);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
}

.footer {
  background-color: var(--off-white);
  padding: 40px 0;
  margin-top: 40px;
  border-top: 1px solid var(--light-gray);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-links a {
  color: var(--dark-gray);
  text-decoration: none;
  font-weight: 500;
  transition: color var(--transition-speed) ease;
}

.footer-links a:hover {
  color: var(--lebanese-green);
}

.social-links {
  display: flex;
  gap: 20px;
}

.social-icon {
  width: 24px;
  height: 24px;
  transition: opacity var(--transition-speed) ease;
  fill: var(--dark-gray);
}

.social-icon:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 30px;
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    gap: 15px;
  }
}